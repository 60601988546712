import React, {ChangeEvent, Dispatch, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {Button, TableCell, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AppState, BillingSettings} from "../../models";
import {ALL_BILLING_SETTING_KEYS, BillingSetting, BillingSettingKey} from "../../api/models";
import {AnyAction} from "redux";
import apiClient, {BillingSettingResponse} from "../../api";

export function billingSettingsLoaded(state: AppState, action: AnyAction): AppState {
    const values = action.values as BillingSettingResponse[];

    const mapValues = () => values.map(v => ({ id: v.key, value: v.value }));

    const billingSettings: BillingSettings = {
        currentSettings: mapValues(),
        savedSettings: mapValues(),
        isFetching: false
    };

    return { ...state, billingSettings };
}

export function billingSettingsNewUpdate(state: AppState, id: BillingSettingKey, value: string): AppState {
    console.log("2. billingSettingsNewUpdate(", id, ",", value, ")");

    const existingBillingSettings = state.billingSettings;

    const currentSettings = [ ...existingBillingSettings.currentSettings ];

    const itemToChangeIndex = currentSettings.findIndex(s => (s.id === id));
    if (itemToChangeIndex < 0) {
        return state;
    }

    currentSettings[itemToChangeIndex] = { id, value };

    const billingSettings: BillingSettings = { ...existingBillingSettings, currentSettings };

    console.log("3. billingSettingsNewUpdate(", billingSettings, ")");

    return { ...state, billingSettings };
}

export function billingSettingsSave(state: AppState, id: BillingSettingKey, dispatch: Dispatch<any>): AppState {
    const currentSetting = state.billingSettings.currentSettings.find(s => (s.id === id));
    if (!currentSetting) {
        return state;
    }

    apiClient
        .setBillingSetting(currentSetting.id, currentSetting.value)
        .then(() => {
            dispatch({
                type: "BILLING_SETTINGS_SAVED",
                id
            });
        });

    return state;
}

export function billingSettingsSaved(state: AppState, id: BillingSettingKey): AppState {
    const existingBillingSettings = state.billingSettings;

    const currentSetting = existingBillingSettings.currentSettings.find(s => (s.id === id));
    if (!currentSetting) {
        return state;
    }

    const savedSettings = [ ...existingBillingSettings.savedSettings ];
    const savedSetting = savedSettings.find(s => (s.id === id));
    if (!savedSetting) {
        return state;
    }

    savedSetting.value = currentSetting.value;

    const billingSettings: BillingSettings = { ...state.billingSettings, savedSettings };

    return { ...state, billingSettings };
}

export function loadBillingSettings(state: AppState, action: AnyAction) {
    const billingSettings: BillingSettings = {
        ...state.billingSettings,
        isFetching: true
    };

    const promises = ALL_BILLING_SETTING_KEYS
        .map(key => apiClient.fetchBillingSetting(key));

    Promise
        .all(promises)
        .then(values => {
            action.dispatch({ type: "BILLING_SETTINGS_LOADED", values });
        });


    return { ...state, billingSettings };
}

export function FraudScreeningSettingsView() {
    const dispatch = useDispatch();

    const isFeatureChanged = (id: string) => {
        const savedSetting = savedSettings.find(s => (s.id === id));
        if (!savedSetting) {
            return false;
        }

        const currentSetting = currentSettings.find(s => (s.id === id));
        if (!currentSetting) {
            return false;
        }

        return (savedSetting.value !== currentSetting.value);
    };

    const isFetching = useSelector<AppState, boolean>(
        state => state.billingSettings.isFetching);

    const savedSettings = useSelector<AppState, BillingSetting[]>(
        state => state.billingSettings.savedSettings);

    const currentSettings = useSelector<AppState, BillingSetting[]>(
        state => state.billingSettings.currentSettings);

    const saveSetting = (setting: BillingSetting) => {
        return () => dispatch({
            type: "BILLING_SETTINGS_SAVE",
            id: setting.id,
            dispatch
        });
    };

    const setCurrentSettingValue = (setting: BillingSetting) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            console.log("1. setCurrentSettingValue(", event.target.value, ")");

            dispatch({
                type: "BILLING_SETTINGS_NEW_UPDATE",
                id: setting.id,
                value: event.target.value
            });
        };
    };

    useEffect(() => {
        if (savedSettings.length === 0) {
            dispatch({ type: "BILLING_SETTINGS_LOAD", dispatch })
        }
    });

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Значение</TableCell>
                            <TableCell>Операции</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { currentSettings.map(setting => {
                            return <TableRow>
                                <TableCell>{setting.id}</TableCell>
                                <TableCell>
                                    <TextField size="small" variant="standard" value={setting.value}
                                               onChange={setCurrentSettingValue(setting)} />
                                </TableCell>
                                <TableCell>
                                    <Button size="small" variant="contained" color="primary"
                                            disabled={!isFeatureChanged(setting.id)}
                                            onClick={saveSetting(setting)}>
                                        Сохранить
                                    </Button>
                                </TableCell>
                            </TableRow>;
                        }) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>;
}
