import React from "react";
import {useHistory} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";

export default function NavigationView() {
    const history = useHistory();
    const goMain = () => {
        history.push("/");
    };
    const goUsersList = () => {
        history.push("/streamers");
    };
    return <div>
        <ListItem button onClick={goMain}>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Общие сведения"/>
        </ListItem>
        <ListItem button onClick={goUsersList}>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Стримеры"/>
        </ListItem>
    </div>;
}
