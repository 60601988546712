import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from "@material-ui/core/TableContainer";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from "@material-ui/core/styles/makeStyles";

import {AnyAction} from "redux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import clsx from "clsx";

import {defaultStyles, defaultToolbarStyles, EnhancedTableHead, HeadCell} from "../../components/list";

import {UserListItem} from "../../api/models";
import {AppState, UsersSearchRefine} from "../../models";

import apiClient from "../../api";
import {getComparator, stableSort} from "../../utils/list";

const useStyles = makeStyles(theme => ({
    progress: {
        position: "absolute",
        right: "40vw",
        bottom: "50vh"
    },
    noselect: {
        userSelect: "none"
    },
    disabled: {
        "@global": {
            "td": {
                color: "rgba(127, 127, 127, 0.5)"
            }
        }
    },
    userName: {
        maxWidth: "250px"
    },
    userDisplayName: {
        maxWidth: "300px"
    }
}));

const headCells: HeadCell<UserListItem>[] = [
    {id: "name", label: "имя", disablePadding: true},
    {id: "displayName", label: "отображать как"},
    {id: "socialType", label: "вход через"},
    {id: "verified", label: "уприд", type: Boolean},
    {id: "registeredAt", label: "зарегистрирован"},
    {id: "donatedTotal", label: "донаты (₽)", type: Number},
    {id: "status", label: "статус", type: Boolean}
];

interface ToolbarProps {
    text?: string,
    onChange: (text?: string) => void;
}

function EnhancedTableToolbar(props: ToolbarProps) {
    const classes = defaultToolbarStyles();
    const [text, setText] = useState(props.text);
    const [vv, setV] = useState<any | null>(null);
    const debounce = (text?: string, force: boolean = false) => {
        const value = !!(text?.trim()?.length) ? text?.trim() : undefined;
        if (vv != null) {
            clearTimeout(vv);
        }
        if (force) {
            props.onChange(value);
        } else {
            setV(setTimeout(() => {
                props.onChange(value);
            }, 500));
        }
    }
    return (
        <Toolbar className={classes.root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
                Пользователи
            </Typography>
            <Tooltip title="поиск">
                <TextField
                    className={classes.search}
                    value={text || ""}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            debounce(text, true);
                        }
                    }}
                    onChange={event => {
                        setText(event.target.value);
                        debounce(event.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Tooltip>
        </Toolbar>
    );
}

export function usersListAction(state: AppState, action: AnyAction) {
    const streamers = state.streamers;
    const newState = { ...streamers, ...action.payload };

    if (action.payload.refine) {
        newState.refine = { ...streamers.refine, ...action.payload.refine };
    }

    if (newState.fetching) {
        apiClient.fetchUsersList(
                newState.refine.orderBy,
                newState.refine.orderDirection, newState.refine.search, newState.refine.nextPageToken)
            .then(result => {
                action.dispatch({
                    type: "USERS_LIST",
                    payload: {
                        fetching: false,
                        loadedAll: result.items.length < newState.refine.maxResults,
                        items: result.items,
                        refine: {
                            nextPageToken: result.nextPageToken
                        }
                    }
                });
            })
            .catch(() => action.dispatch({
                type: "USERS_LIST",
                payload: { fetching: false }
            }));
    }

    return {...state, ...{streamers: newState}}
}

export default function UsersListView() {
    const defaultClasses = defaultStyles();
    const customClasses = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const items = useSelector<AppState, UserListItem[] | undefined>(state => state.streamers.items, shallowEqual);
    const isFetching = useSelector<AppState, boolean>(state => state.streamers.fetching);
    const loadedAll = useSelector<AppState, boolean>(state => state.streamers.loadedAll);
    const refine = useSelector<AppState, UsersSearchRefine>(state =>
        state.streamers.refine, shallowEqual);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof UserListItem) => {
        const isAsc = refine.orderBy === property && refine.orderDirection === "asc";
        if (isFetching) return;
        if (loadedAll) {
            dispatch({
                type: "USERS_LIST",
                payload: {
                    refine: {
                        orderBy: property,
                        orderDirection: isAsc ? "desc" : "asc"
                    }
                }
            });
        } else {
            dispatch({
                type: "USERS_LIST",
                dispatch,
                payload: {
                    fetching: true,
                    refine: {
                        nextPageToken: undefined,
                        orderBy: property,
                        orderDirection: isAsc ? "desc" : "asc"
                    }
                }
            });
        }
    };

    const handleRequestSearch = (text?: string) => {
        if (isFetching) return;
        dispatch({
            type: "USERS_LIST",
            dispatch,
            payload: {
                loadedAll: false,
                fetching: true,
                refine: {
                    nextPageToken: undefined,
                    search: text
                }
            }
        });
    };

    const handleRequestMore = () => {
        if (isFetching || loadedAll) return;
        dispatch({type: "USERS_LIST", payload: {fetching: true}, dispatch});
    };

    useEffect(() => {
        if (!items) {
            dispatch({type: "USERS_LIST", payload: {fetching: true}, dispatch})
        }
        function onScroll(event: Event) {
            const target = (event as CustomEvent).detail as HTMLElement;
            if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                handleRequestMore();
            }
        }
        document.addEventListener("main-scroll", onScroll);
        return function cleanup() {
            document.removeEventListener("main-scroll", onScroll);
        }
    }, []);

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper className={defaultClasses.paper}>
                <EnhancedTableToolbar text={refine.search} onChange={handleRequestSearch}/>
                {isFetching && <CircularProgress className={customClasses.progress} />}
                <TableContainer>
                    <Table
                        className={defaultClasses.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={defaultClasses}
                            headCells={headCells}
                            order={refine.orderDirection}
                            orderBy={refine.orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(items || [], getComparator(
                                refine.orderDirection, refine.orderBy))
                                .map((user: UserListItem) => {
                                    const rowClasses = (user.status)
                                        ? clsx(customClasses.noselect) : clsx(customClasses.noselect,
                                            customClasses.disabled);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() =>
                                                history.push(`/streamers/${user.refId}`)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={user.refId}
                                            className={rowClasses}
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell className={clsx(customClasses.userName)}>
                                                {user.name}
                                            </TableCell>
                                            <TableCell className={clsx(customClasses.userDisplayName)}>
                                                {user.displayName}
                                            </TableCell>
                                            <TableCell>{user.socialType}</TableCell>
                                            <TableCell align="right">{user.verified ? "✓" : ""}</TableCell>
                                            <TableCell>{user.registeredAt.toLocaleString()}</TableCell>
                                            <TableCell align="right">{user.donatedTotal.toFixed(0)}</TableCell>
                                            <TableCell align="right">{user.status ? "✓" : ""}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    </Grid>;
}
