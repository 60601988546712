import React from "react";

interface UserAvatarProps {
    picture?: string
}

export default function UserAvatar(props: UserAvatarProps) {
    return (
        <React.Fragment>
            <img alt="" style={{width: "100%"}} src={props.picture || "/avatar.png"}/>
        </React.Fragment>
    );
}
