import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ListItemText from "@material-ui/core/ListItemText";

const linkStyle = {
    color: "rgba(0, 0, 0, 0.87)",
    textDecoration: "none"
};

function getReportsQuarterRange(): string {
    const now = new Date();
    const current = now.toISOString().substring(0, 7);

    now.setMonth(now.getMonth() - 1);
    const prev1 = now.toISOString().substring(0, 7);

    now.setMonth(now.getMonth() - 1);
    const prev2 = now.toISOString().substring(0, 7);

    now.setMonth(now.getMonth() - 1);
    const prev3 = now.toISOString().substring(0, 7);

    return `${prev3},${prev2},${prev1},${current}`;
}

export default function ReportsView() {
    const current = "/api/reports/month"
    const quarterRange = `/api/reports/month/${getReportsQuarterRange()}`;
    const cohortsByDonation = "/api/reports/cohorts/first-donation"

    return <div>
        <ListSubheader inset>Отчеты</ListSubheader>
        <a style={linkStyle} href={current} download>
            <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Текущий месяц"/>
            </ListItem>
        </a>
        <a style={linkStyle} href={quarterRange} download>
            <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Квартал"/>
            </ListItem>
        </a>
        <a style={linkStyle} href={cohortsByDonation} download>
            <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Когортный анализ"/>
            </ListItem>
        </a>
    </div>;
}
