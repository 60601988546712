import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "./components/Chart";
import ActiveUsers from "./components/ActiveUsers";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function MainView() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
                <Chart/>
            </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
                <ActiveUsers/>
            </Paper>
        </Grid>
    </Grid>;
}
