import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {EnhancedTableHead, HeadCell, defaultStyles, defaultToolbarStyles} from "../../../components/list";

import {getComparator, Order, stableSort} from "../../../utils/list";
import preventDefault from "../../../utils/preventDefault";
import {Transaction} from "../../../api/models";
import apiClient from "../../../api";

const headCells: HeadCell<Transaction>[] = [
    {id: "amount", label: "сумма", type: Number},
    {id: "commission", label: "комиссия", type: Number},
    {id: "method", label: "метод"},
    {id: "date", label: "дата"},
    {id: "number", label: "ном.операции"},
    {id: "signature", label: "донатер"},
    {id: "message", label: "сообщение"}
];

function EnhancedTableToolbar() {
    const classes = defaultToolbarStyles();
    return (
        <Toolbar className={classes.root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
                Донаты
            </Typography>
        </Toolbar>
    );
}

interface DonationsProps {
    items: Transaction[]
}

function Donations(defaultProps: DonationsProps) {
    const classes = defaultStyles();
    // const [props] = React.useState<DonationsProps>(defaultProps);
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof Transaction>("date");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Transaction) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (<Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(defaultProps.items, getComparator(order, orderBy))
                                .map(donation => {
                                    return (<TableRow
                                        hover
                                        onClick={preventDefault}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={donation.refId}>
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                        <TableCell align="right">{donation.amount.toFixed(2)}</TableCell>
                                        <TableCell align="right">{donation.commission.toFixed(2)}</TableCell>
                                        <TableCell>{donation.method}</TableCell>
                                        <TableCell>{donation.date.toLocaleString()}</TableCell>
                                        <TableCell>{donation.number || ""}</TableCell>
                                        <TableCell>{donation.signature || ""}</TableCell>
                                        <TableCell>{donation.message || ""}</TableCell>
                                    </TableRow>);
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    </Grid>);
}

export default function SuspendDonations() {
    const {id} = useParams<{ id: string }>();
    const [data, setData] = useState<{ loading: boolean, items: Transaction[] }>(
        {loading: true, items: []});
    useEffect(() => {
        apiClient.fetchTransactions(id).then(result => {
            setData({
                loading: false,
                items: result.items
            });
        }).catch(error => {
            console.error(error);
        });
    }, []);
    return <Donations items={data.items}/>
}
