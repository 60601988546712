import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";

import {PageLoader} from "../../components/loaders";
import Donations from "./components/Donations";
import ProfileInfo from "./components/ProfileInfo";
import UserAvatar from "./components/UserAvatar";

import apiClient from "../../api";
import {UserBillingState, UserProfile} from "../../api/models";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    avatar: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
    }
}));

function UserProfileView(props: { user: UserProfile, userBilling: UserBillingState }) {
    const classes = useStyles();

    const avatar = clsx(classes.avatar);
    const fixedHeightPaper = clsx(classes.paper);

    return <Grid container spacing={3}>
        <Grid item xs={4} md={4} lg={3}>
            <Paper className={avatar}>
                <UserAvatar picture={props.user.picture}/>
            </Paper>
        </Grid>
        <Grid item xs={8} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
                <ProfileInfo {...props} />
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Donations/>
        </Grid>
    </Grid>;
}

export default function SuspendUserProfileView() {
    const {id} = useParams<{ id: string }>();

    const [data, setData] = useState<{ loading: boolean, user: UserProfile | null }>({
        loading: true,
        user: null
    });

    const [userBilling, setUserBilling] =
        useState<{ loading: boolean, userBilling: UserBillingState | null }>({
            loading: true,
            userBilling: null
        });

    useEffect(() => {
        apiClient.fetchUserProfile(id).then(user => {
            let tariff = user.tariff.name;
            switch (user.tariff.name) {
                case "STANDARD":
                    tariff = "стандарт"
                    break;
                case "ANONYMOUS":
                    tariff = "анонимный"
                    break;
                case "STANDARD_FREE":
                    tariff = "премиум"
                    break;
            }
            user.tariff.name = `${tariff} (${user.tariff.in.rates.CARD[0].percent}%)`;
            user.registeredAt = new Date(user.registeredAt);
            setData({
                loading: false,
                user
            });
        }).catch(() => {
            // window.location.href = "https://streamer.donatty.com/login";
        });

        apiClient.fetchUserBilling(id)
            .then(userBilling => setUserBilling({loading: false, userBilling}))
            .catch(() => {});
    }, []);

    if (data.loading || userBilling.loading) {
        return <PageLoader/>
    }

    return <UserProfileView user={data.user!!} userBilling={userBilling.userBilling!!}/>
}

