import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/title";
import apiClient from "../../../api";

export default function ActiveUsers() {
    const [data, setData] = useState<number|null>(null);

    useEffect(() => {
        apiClient.fetchActiveUsersCount().then(result => {
            setData(result);
        });
    }, []);

    return (
        <React.Fragment>
            <Title>Активные пользователи</Title>
            <Typography component="p" variant="h4">
                {data != null ? data : ""}
            </Typography>
        </React.Fragment>
    );
}
