import React, {useEffect, useState} from "react";
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from "recharts";
import Title from "../../../components/title";
import apiClient from "../../../api";

export default function Chart() {
    const theme = useTheme();
    const [data, setData] = useState<{time: string, amount: number}[]>([]);

    useEffect(() => {
        apiClient.fetchTotalLastDays().then(result => {
            setData(result.items.map(it => {
                return {
                    time: it.date.toDateString(),
                    amount: it.amount
                }
            }));
        });
    }, []);

    return (
        <React.Fragment>
            <Title>Донаты</Title>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
                    <YAxis stroke={theme.palette.text.secondary}>
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                        >
                            (₽)
                        </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
