import React from "react";
import {BrowserRouter as Router, Route, Switch, useHistory, useLocation} from "react-router-dom";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {AnyAction, createStore} from "redux";
import {Provider} from "react-redux";

import {AppState} from "./models";
import Copyright from "./components/copyright";

import MainView from "./views/main/MainView";
import UsersListView, {usersListAction} from "./views/users-list/UsersListView";
import UserProfileView from "./views/user-profile/UserProfileView";
import DrawerNavigationView from "./views/drawer/NavigationView";
import DrawerReportsView from "./views/drawer/ReportsView";
import DrawerFraudScreeningView from "./views/drawer/FraudScreeningView";
import {
    FraudScreeningOrdersView,
    ordersListAction,
    suspendedOrdersListAction, suspendedOrdersListAddItem
} from "./views/fraud-screening/FraudScreeningOrdersView";
import {
    billingSettingsLoaded, billingSettingsNewUpdate, billingSettingsSave, billingSettingsSaved,
    FraudScreeningSettingsView,
    loadBillingSettings
} from "./views/fraud-screening/FraudScreeningSettingsView";

const store = createStore(
    function(state: AppState = {
        billingSettings: {
            isFetching: false,
            currentSettings: [],
            savedSettings: []
        },
        streamers: {
            items: undefined,
            fetching: false,
            loadedAll: false,
            refine: {
                search: undefined,
                nextPageToken: undefined,
                orderBy: "registeredAt",
                orderDirection: "desc",
                maxResults: 50
            }
        },
        orders: {
            items: undefined,
            fetching: false,
            loadedAll: false,
            refine: {
                isSuspiciousOnly: true,
                nextPageToken: "",
                orderBy: "streamerName",
                orderDirection: "asc",
                maxResults: 50
            }
        },
        suspendedOrders: {
            items: undefined,
            fetching: false,
            loadedAll: false,
            refine: {
                isSuspiciousOnly: true,
                nextPageToken: "",
                orderBy: "streamerName",
                orderDirection: "asc",
                maxResults: 50
            }
        }
    }, action: AnyAction) {
        switch (action.type) {
            case "BILLING_SETTINGS_LOAD": {
                return loadBillingSettings(state, action);
            }

            case "BILLING_SETTINGS_LOADED": {
                return billingSettingsLoaded(state, action);
            }

            case "BILLING_SETTINGS_NEW_UPDATE": {
                return billingSettingsNewUpdate(state, action.id, action.value);
            }

            case "BILLING_SETTINGS_SAVE": {
                return billingSettingsSave(state, action.id, action.dispatch);
            }

            case "BILLING_SETTINGS_SAVED": {
                return billingSettingsSaved(state, action.id);
            }

            case "USERS_LIST": {
                return usersListAction(state, action);
            }

            case "ORDERS_LIST/CLEAR":
            case "ORDERS_LIST/REPLACE_ITEMS":
            case "ORDERS_LIST": {
                return ordersListAction(state, action);
            }

            case "SUSPENDED/ORDERS_LIST/CLEAR":
            case "SUSPENDED/ORDERS_LIST/REPLACE_ITEMS":
            case "SUSPENDED/ORDERS_LIST": {
                return suspendedOrdersListAction(state, action);
            }

            case "SUSPENDED/ORDERS_LIST/ADD_ITEM": {
                return suspendedOrdersListAddItem(state, action);
            }
        }
        return state
    })

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },

}));

function AppBarTitle(props: { className: string }) {
    const location = useLocation();
    const history = useHistory();
    let title = "";
    const userProfileMatch = new RegExp('/streamers/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}');
    if (location.pathname === "/") {
        title = "Общие сведения";
    } else if (location.pathname === "/streamers") {
        title = "Список пользователей";
    } else if (userProfileMatch.test(location.pathname)) {
        title = "Профиль";
    } else if (location.pathname === "/fraud-screening/orders") {
        title = "Антифрод";
    } else if (location.pathname === "/fraud-screening/orders/suspended") {
        title = "Морозилка";
    } else if (location.pathname === "/fraud-screening/settings") {
        title = "Настройки фрод-скрининга";
    }else {
        history.replace("/");
    }
    return <Typography component="h1" variant="h6" color="inherit" noWrap className={props.className}>
        {title}
    </Typography>
}

export default function App() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Provider store={store}>
            <Router>
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <AppBarTitle className={classes.title}/>
                            {/*<IconButton color="inherit">*/}
                            {/*    <Badge badgeContent={4} color="secondary">*/}
                            {/*        <NotificationsIcon/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider/>
                        <List>
                            <DrawerNavigationView/>
                        </List>
                        <Divider/>
                        <List>
                            <DrawerFraudScreeningView/>
                        </List>
                        <Divider/>
                        <List>
                            <DrawerReportsView/>
                        </List>
                    </Drawer>
                    <main className={classes.content}
                          onScroll={(event) => {
                              document.dispatchEvent(new CustomEvent("main-scroll", {
                                  detail: event.target
                              }));
                          }}>
                        <div className={classes.appBarSpacer}/>
                        <Container maxWidth="lg" className={classes.container}>
                            <Switch>
                                <Route exact path="/">
                                    <MainView/>
                                </Route>
                                <Route path="/streamers/:id">
                                    <UserProfileView/>
                                </Route>
                                <Route path="/streamers">
                                    <UsersListView/>
                                </Route>
                                <Route path="/fraud-screening/orders/:type?">
                                    <FraudScreeningOrdersView />
                                </Route>
                                <Route path="/fraud-screening/settings">
                                    <FraudScreeningSettingsView />
                                </Route>
                            </Switch>
                            <Box pt={4}>
                                <Copyright/>
                            </Box>
                        </Container>
                    </main>
                </div>
            </Router>
        </Provider>
    );
}
