import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {useHistory} from "react-router-dom";

export default function NavigationView() {
    const history = useHistory();

    const goOrdersList = () => {
        history.push("/fraud-screening/orders");
    };

    const goSettings = () => {
        history.push("/fraud-screening/settings");
    };

    const goSuspendedOrdersList = () => {
        history.push("/fraud-screening/orders/suspended");
    };

    return <div>
        <ListItem button onClick={goOrdersList}>
            <ListItemIcon>
                <FlashOnIcon/>
            </ListItemIcon>
            <ListItemText primary="Антифрод"/>
        </ListItem>
        <ListItem button onClick={goSuspendedOrdersList}>
            <ListItemIcon>
                <AcUnitIcon/>
            </ListItemIcon>
            <ListItemText primary="Морозилка"/>
        </ListItem>
        <ListItem button onClick={goSettings}>
            <ListItemIcon>
                <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Настройки"/>
        </ListItem>
    </div>;
}